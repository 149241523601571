<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterDistributorCurah
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
          <div class="col-lg-6 col-md-12"></div>
          <div class="col-lg-6 col-md-12 text-right">
            <a-button
                title="download excel"
                type="primary"
                @click="btnDownloadExcel"
                :loading="state.isDownloading">
                <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownloading"></i>
                <span v-else>Downloading ...</span>
            </a-button>
          </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                <template #action="{ record }">
                    <span>
                        <ATooltip title="Detail">
                            <button
                                type="button"
                                @click="btnDetail(record.sow_kompetitor)"
                                class="btn btn-sm btn-success"
                                shape="round"
                                size="small">
                                <i class="fe fe-info fa-lg" />&nbsp;Detail
                            </button>
                        </ATooltip>
                    </span>
                </template>
            </MdTable>
        </div>

        <!-- details by id -->
        <DetailSowDistributorCurah
            v-if="visibleDetailModal"
            v-model:visible="visibleDetailModal"
            v-model:items="visibleDetailItemsModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import DetailSowDistributorCurah from './DetailSowDistributorCurah'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterProvince,
        FilterKabupatenKota,
        FilterRegional,
        FilterDistributorCurah,
        DetailSowDistributorCurah,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Customer Curah',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Customer Curah',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Kode Distributor Curah',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor Curah',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'SOW SIG',
                    dataIndex: 'sow_sig_percentage',
                },

                {
                    title: 'SOW Kompetitor',
                    dataIndex: 'sow_kompetitor_percentage',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                toko: [],
                // date: moment(new Date()).startOf('date').format('YYYY-MM-DD'),
                distributor: [],
                page: 1,
                "per-page": 10,
            }),
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.date) {
                params.date = moment(state.params.date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get('/api/report/sales-sow', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // download export excel
        const btnDownloadExcel = () => {
            state.isDownloading = true
            apiClient
                .get('/api/report/sales-sow', {
                    params: {
                        ...queryParams(),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `SOW-Distributor-Curah_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.isDownloading = false
                })
        }

        // handle detail by item
        const visibleDetailModal = ref(false);
        const visibleDetailItemsModal = ref(null);

        const btnDetail = (items) => {
            visibleDetailModal.value = true
            visibleDetailItemsModal.value = items
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            // rowSelection,
            handleTableChange,
            btnDownloadExcel,
            errorMessage,
            visibleDetailModal,
            visibleDetailItemsModal,
            btnDetail,
        }
    },
})
</script>
